import * as yup from "yup";

import { CustomModelLabelSchemaTypeDefintion } from "../constants/customModelLabelSchema";
import { BriefWorkspace, briefWorkspaceSchema } from "./briefWorkspace";
import {
  ExtractedContentSchema,
  extractedContentSchemaSchema,
} from "./extractedContentSchema";
import { FSLModelState, fslModelStateSchema } from "./fslModelState";
import { LLMInputType } from "./llmCompletion";
import { CustomModelMapper } from "./mappers/customModel";
import { pageInfoSchema, pageInfoWithOffsetSchema } from "./pageInfo";

export type CustomModelStatus =
  | "not_synced"
  | "labelling"
  | "training"
  | "trained";

export enum CustomModelExtraFieldType {
  StandardMobelLabelledImageCount = "standard_model_labelled_image_count",
}

const customModelStatusSchema = yup
  .string()
  .oneOf(["not_synced", "labelling", "training", "trained"]);

export const getUploadURLResponseSchema = yup
  .object({
    url: yup.string().defined(),
    data: yup.mixed((_x): _x is { [key: string]: string } => true).defined(),
    fileField: yup.string().defined(),
    assetId: yup.string().defined(),
  })
  .camelCase();

export const previewImageSchema = yup
  .object({
    assetId: yup.string().required(),
    contentType: yup.string().defined().nullable(),
    url: yup.string().defined().nullable(),
  })
  .camelCase();

export const briefCustomModelSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    previewImage: previewImageSchema.nullable(),
    noOfSampleImages: yup.number().required(),
    createdAt: yup.date().required(),
    status: customModelStatusSchema.required(),
    resourceOwnerId: yup.string().required(),
  })
  .camelCase();

export const paginatedBriefCustomModelSchema = yup
  .object({
    pageInfo: pageInfoSchema.required(),
    customModels: yup.array(briefCustomModelSchema).defined(),
  })
  .camelCase();

const customModelVersionRespSchema = yup.object({
  tag: yup.string().defined(),
  created_at: yup.number().required(),
  fields: yup.array(yup.string().defined()).optional(),
});

const customModelLabelSchemaSchema = yup.object({
  name: yup.string().defined(),
  color: yup.string().optional(),
  type: yup.string().defined(),
  attributes: yup
    .array(
      yup.object({
        name: yup.string().defined(),
        choices: yup.array(yup.string().defined()).optional(),
      })
    )
    .defined(),
  config: yup
    .object({
      format: yup.mixed<CustomModelLabelSchemaTypeFormatType>().optional(),
    })
    .optional(),
});

/* NOTE(jasonkit):
 * CustomModelLLMParameters are intentally keep the fields in snake_case
 * and its only exposing fields that might let user to config in the future.
 * It could contains extra fields and portal should preserve them when updating
 * those exposed fields.
 */
const customModelLLMParametersSchema = yup.object({
  should_preserve_horizontal_whitespace: yup.boolean().optional(),
  should_preserve_vertial_whitespace: yup.boolean().optional(),
  should_split_prompt: yup.boolean().optional(),
  should_separate_prompt_by_fields: yup.boolean().optional(),
  should_add_border_line_to_ocr: yup.boolean().optional(),
  top_p: yup.number().optional(),
  presence_penalty: yup.number().optional(),
  frequency_penalty: yup.number().optional(),
  model: yup.string().optional(),
  input_type: yup.string().oneOf(Object.values(LLMInputType)).optional(),
});

export type CustomModelLLMParameters = yup.InferType<
  typeof customModelLLMParametersSchema
>;

const _separatePromptByFieldsConfigObjectSchema = yup.object().shape({
  field_names: yup.array(yup.string().defined()).required(),
  llm_provider: yup.string().optional(),
  llm_parameters: customModelLLMParametersSchema.default(undefined).optional(),
  prompt_version: yup.string().optional(),
  prompt_type: yup.string().optional(),
  forced_llm_model: yup.string().optional(),
});

type _SeparatePromptByFieldsConfigObject = yup.InferType<
  typeof _separatePromptByFieldsConfigObjectSchema
>;

const separatePromptByFieldsConfigSchema = yup
  .mixed(
    (input): input is _SeparatePromptByFieldsConfigObject | Array<string> =>
      _separatePromptByFieldsConfigObjectSchema.isValidSync(input) ||
      (Array.isArray(input) && input.every(item => typeof item === "string"))
  )
  .transform((value, _input, _ctx) => {
    if (_separatePromptByFieldsConfigObjectSchema.isValidSync(value))
      return value;
    return _separatePromptByFieldsConfigObjectSchema.validateSync({
      field_names: value,
    });
  })
  .defined();

export type SeparatePromptByFieldsConfigSchemaStorage = yup.InferType<
  typeof separatePromptByFieldsConfigSchema
>;

export type SeparatePromptByFieldsConfig = {
  fieldNames: string[];
  llmProvider?: string;
  llmParameters?: CustomModelLLMParameters;
  promptVersion?: string;
  promptType?: string;
  forcedLLMModel?: string;
};

export const customModelExtraFieldsRespSchema = yup.object({
  standard_model_labelled_image_count: yup.number().optional(),
});

export const customModelRespSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  created_at: yup.string().defined(),
  updated_at: yup.string().defined(),
  config: yup
    .object({
      remark: yup.string().defined(),
      training_requested: yup.boolean().required(),
      labelling_started: yup.boolean().optional(),
      fxcm_type: yup.string().optional(),
      label_schema: yup.array(customModelLabelSchemaSchema).optional(),
      unfrozen_label_schema: yup.array(customModelLabelSchemaSchema).optional(),
      based_on: yup.string().defined().optional(),
      is_fsl_model: yup.boolean().optional(),
      extracted_content_schema: extractedContentSchemaSchema
        .default(undefined)
        .optional(),
      fsl_model_state: fslModelStateSchema.default(undefined).optional(),
      standard_model_sample_updated_at: yup.number().optional(),
      llm_provider: yup.string().optional(),
      llm_parameters: customModelLLMParametersSchema.optional(),
      document_type_for_sample: yup.string().optional(),
      separate_prompt_by_fields: yup
        .array(separatePromptByFieldsConfigSchema)
        .optional(),
    })
    .required(),
  cvat_project_id: yup.string().defined().nullable(),
  form_id: yup.string().defined().nullable(),
  last_cvat_project_start_sync_at: yup.string().defined().nullable(),
  last_cvat_project_finish_sync_at: yup.string().defined().nullable(),
  start_training_at: yup.string().defined().nullable(),
  finish_training_at: yup.string().defined().nullable(),
  is_training_failed: yup.boolean(),
  start_deployment_at: yup.string().defined().nullable(),
  finish_deployment_at: yup.string().defined().nullable(),
  deployed_model_version: yup.string().defined().nullable(),
  model_versions: yup.array(customModelVersionRespSchema).defined(),
  model_version_in_training: customModelVersionRespSchema.defined().nullable(),
  resource_owner_id: yup.string().defined(),
  status: customModelStatusSchema.required(),
  no_of_sample_images: yup.number().required(),
  workspaces: yup.array(briefWorkspaceSchema).defined(),
  extra_fields: customModelExtraFieldsRespSchema.optional(),
});

export const customModelSchema = yup
  .mixed((_x): _x is CustomModel => true)
  .defined()
  .test((_value, context) => {
    customModelRespSchema.validateSync(context.originalValue);
    return true;
  })
  .transform((_, resp) => CustomModelMapper.fromResp(resp));

export const customModelOptionSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
  })
  .camelCase();

export type PreviewImage = yup.InferType<typeof previewImageSchema>;
export type BriefCustomModel = yup.InferType<typeof briefCustomModelSchema>;
export type PaginatedBriefCustomModel = yup.InferType<
  typeof paginatedBriefCustomModelSchema
>;

export type CustomModelExtraFieldsResp = yup.InferType<
  typeof customModelExtraFieldsRespSchema
>;

export type CustomModelResp = yup.InferType<typeof customModelRespSchema>;
export type CustomModelVersionResp = yup.InferType<
  typeof customModelVersionRespSchema
>;

export type CustomModelOption = yup.InferType<typeof customModelOptionSchema>;

export type CustomModelSampleImage = {
  assetId: string;
  filename: string;
  updatedAt: number;
  contentType: string;
  isOnCVAT: boolean;
  url?: string;
};

export type CustomModelLabelSchemaType =
  keyof typeof CustomModelLabelSchemaTypeDefintion;

export type CustomModelLabelSchemaTypeFormatDate =
  (typeof CustomModelLabelSchemaTypeDefintion.date.format)[number];

export type CustomModelLabelSchemaTypeFormatTime =
  (typeof CustomModelLabelSchemaTypeDefintion.time.format)[number];

export type CustomModelLabelSchemaTypeFormatAmount =
  (typeof CustomModelLabelSchemaTypeDefintion.amount.format)[number];

export type CustomModelLabelSchemaTypeFormatType =
  | CustomModelLabelSchemaTypeFormatDate
  | CustomModelLabelSchemaTypeFormatTime
  | CustomModelLabelSchemaTypeFormatAmount;

export type CustomModelLabelSchemaAttribute = {
  name: string;
  choices?: string[];
};

export type CustomModelLabelSchemaConfig = {
  format?: CustomModelLabelSchemaTypeFormatType;
};

export type CustomModelLabelSchema = {
  name: string;
  color?: string;
  type: CustomModelLabelSchemaType;
  attributes: CustomModelLabelSchemaAttribute[];
  config?: CustomModelLabelSchemaConfig;
};

export type CustomModelVersion = {
  tag: string;
  createdAt: Date;
  fields?: string[];

  isInTraining: boolean;
  isActive: boolean;
};

export type CustomModelExtraFields = {
  standardModelLabelledImageCount?: number;
};

export type CustomModelConfig = {
  remark: string;
  trainingRequested: boolean;
  labellingStarted: boolean;
  fxcmType: string;
  labelSchema: CustomModelLabelSchema[];
  unfrozenLabelSchema: CustomModelLabelSchema[];
  basedOn?: string;
  isFSLModel?: boolean;
  extractedContentSchema?: ExtractedContentSchema;
  fslModelState?: FSLModelState;
  standardModelSampleUpdatedAt?: number;
  llmProvider?: string;
  llmParameters?: CustomModelLLMParameters;
  documentTypeForSample?: string;
  separatePromptByFields: SeparatePromptByFieldsConfig[];
};

export type CustomModel = {
  id: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  config: CustomModelConfig;
  CVATProjectID: string | null;
  formID: string | null;
  lastCVATProjectStartSyncAt: number | null;
  lastCVATProjectFinishSyncAt: number | null;
  startTrainingAt: number | null;
  finishTrainingAt: number | null;
  isTrainingFailed: boolean;
  startDeploymentAt: number | null;
  finishDeploymentAt: number | null;
  modelVersions: CustomModelVersion[];
  resourceOwnerId: string;
  status: CustomModelStatus;
  noOfSampleImages: number;
  workspaces: BriefWorkspace[];
  deployedModelVersion?: string;
  extraFields?: CustomModelExtraFields;
  documentTypeForSample?: string;
};

export const paginatedWithOffsetBriefCustomModelSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    customModels: yup.array(briefCustomModelSchema).defined(),
  })
  .camelCase();

export type PaginatedWithOffsetBriefCustomModel = yup.InferType<
  typeof paginatedWithOffsetBriefCustomModelSchema
>;

export interface CustomModelImageExtraInfo {
  fslInput?: string;
  fslOutput?: string;
  isExtractionDisabled?: boolean;
  groupId?: string;
  groupItemIndex?: number;
}

export enum CustomModelNotificationType {
  ClickedGetHelp = "custom_model_clicked_get_help",
  UploadedFirstImage = "custom_model_uploaded_first_image",
  CreatedSchema = "custom_model_created_schema",
}

export enum StandardModelImageState {
  NotStarted = "not_started",
  Annotating = "annotating",
  Annotated = "annotated",
  Reviewed = "reviewed",
}

export enum FSLModelType {
  InstantModel = "instant-model",
  StandardModel = "standard-model",
}
